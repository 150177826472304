
<v-card id="confirmation-cart" :tile="sharpCorners" :show="selectedPackage" class="lg tr-0 tl-0">
  <!-- <component :is="headerComponent" :title="showConfirmationCartHeaderTitle" :image="headerIconOrImage" :color="cartHeaderColor" /> -->
  <CardHeader
    :title="confirmationCartHeaderText"
    :image="confirmationCartHeaderImage"
    :color="confirmationCartHeaderBackgroundColor"
  ></CardHeader>
  <v-container class="pb-0">
    <v-row class="order-id" v-if="showSeperateOrderId">
      <v-col>#{{ orderId }}</v-col>
    </v-row>
    <v-row v-if="confirmationCartInstallationDateHeaderText">
      <v-col id="confirmation-cart-style" class="pb-0 pt-0 grey--text headline">{{
        confirmationCartInstallationDateHeaderText
      }}</v-col>
    </v-row>
    <div v-if="installationDateOverride">
      <v-row>
        <v-col class="pt-0 pb-2" v-html="installationDateOverride"></v-col>
      </v-row>
    </div>
    <div v-else-if="confirmationCartInstallationDateHeaderText">
      <!-- <div v-if="schedule?.installOption === 'nextAvailable' || schedule?.installOption === 'firstAvailable'">
        <v-row>
          <v-col class="pt-0 pb-2"> <span v-html="installationDateHtml"> </span></v-col>
        </v-row>
      </div> -->
      <!-- <div v-if="schedule?.installOption === 'AM' || schedule?.installOption === 'PM'">
        <v-row>
          <v-col class="pt-0 pb-2">{{ schedule?.installOption === 'AM' ? 'AM Installation' : 'PM Installation'
          }}</v-col>
        </v-row>
      </div> -->
      <div v-if="schedule?.installOption === 'chooseMyDates'">
        <v-row class="pb-1 preference-date" v-for="(s, index) in schedule.timeSlots" :key="s.startTime">
          <v-col class="py-0">{{ index === 0 ? 'First Preference' : 'Second Preference' }}</v-col>
          <v-col class="py-0">
            <v-row>
              {{ index === 0 ? getMoment(schedule.scheduleDate, 'MM-DD-YYYY') : getMoment(schedule.scheduleDate2, 'MM-DD-YYYY') }}
            </v-row>
            <v-row>
              {{ s.label }}
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="schedule?.installOption === 'automated'">
        <v-row>
          <v-col
            v-if="customAutomatedAppointmentConfirmationText"
            v-html="uiMacroParser(shopper, customAutomatedAppointmentConfirmationText, true)"
          >
          </v-col>
          <v-col v-else class="py-0">
            Your appointment is confirmed for:
            {{ getMoment(schedule.scheduleDate, 'MM-DD-YYYY') }}
            {{ schedule.timeSlots[0].label }}
          </v-col>
        </v-row>
      </div>
      <div v-else-if="customScheduleOptionsInfo">
        <v-list-item-subtitle class="text-wrap" v-html="customScheduleOptionsInfo" />
      </div>
      <div v-if="schedule?.confirmationOption">
        <v-row>
          <v-col v-if="schedule?.confirmationOption === 'email'" class="pt-0 pb-2">{{ 'Email to confirm' }}</v-col>
          <v-col v-else-if="schedule?.confirmationOption === 'call'" class="pt-0 pb-2">{{ 'Call to confirm' }}</v-col>
          <v-col v-else-if="customConfirmationOptionsInfo" class="pt-0 pb-2" v-html="customConfirmationOptionsInfo" />
        </v-row>
      </div>
    </div>
    <v-divider v-if="confirmationCartInstallationDateHeaderText" class="card-divider"></v-divider>
    <v-container class="pb-2 pa-0">
      <v-row>
        <v-col
          id="confirmation-cart-style"
          :class="confirmationCartInstallationDateHeaderText ? 'pb-0 grey--text headline' : 'pt-0 pb-0 grey--text headline'"
          >Customer Details</v-col
        >
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">{{ shopper.firstName }} {{ shopper.lastName }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">{{ shopper.punctuatedAddress }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">{{ shopper.phone }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">{{ shopper.email }}</v-col>
      </v-row>
      <v-row v-if="externalAccountNumberConfirmationString && displayExternalAccountNumberInConfirmationCart">
        <v-col cols="12" class="py-0" v-html="externalAccountNumberConfirmationString"></v-col>
      </v-row>
      <v-row v-if="shopper.customInfo.pin && displayCustomerPinInConfirmation">
        <v-col cols="12" class="py-0">4-Digit Customer PIN: {{ shopper.customInfo.pin }}</v-col>
      </v-row>
      <v-row v-if="newPhoneNum">
        <v-col cols="12" class="py-0">Your new phone number: {{ formatPhoneNumber(newPhoneNum) }}</v-col>
      </v-row>
      <v-row v-show="deets.value" v-for="(deets, i) in additionalCustomerDetails" :key="'deets_' + i">
        <v-col v-if="deets.label" cols="12" class="py-0">{{ deets.label }}: {{ deets.value }}</v-col>
        <v-col v-else cols="12" class="py-0">{{ deets.value }}</v-col>
      </v-row>
    </v-container>
    <v-divider class="card-divider"></v-divider>
    <div class="avoid-break summary pa-0">
      <v-row>
        <v-col id="confirmation-cart-style" class="pb-0 grey--text headline">Order Summary</v-col>
      </v-row>
      <!-- if displayMonthlyChargesWithQuantity is true, then display the monthly charges that share a name, along with their total quantity -->
      <div v-for="item in monthlyChargesMultiples">
        <CartLineItem :item="item" priceType="Monthly Price" :quantity="item.qty"> </CartLineItem>
      </div>
      <div v-for="item in monthlyCharges" :key="item.Name">
        <CartLineItem
          v-if="!displayMonthlyChargesWithQuantity || quantityOfCharge(item.Name) === 1"
          :item="item"
          priceType="Monthly Price"
          :expanded="true"
        />
      </div>
      <div v-for="item in oneTimeCharges" :key="item.Name">
        <CartLineItem :item="item" priceType="OTC" :expanded="true" />
      </div>
      <v-divider class="card-divider"></v-divider>
      <!--      Phone-->
      <div v-if="phone.upgrades.length > 0">
        <v-row v-if="phone.upgrades[0].Name === 'New' && displayNewPhoneChoiceInConfirmationCart">
          <v-col class="pt-2 pb-2 font-weight-thin" v-html="newPhoneChoiceConfirmationCartText"></v-col>
        </v-row>
        <v-row v-if="phone.upgrades[0].Name === 'Keep' && displayKeepPhoneChoiceInConfirmationCart">
          <v-col v-if="phone.phoneNumber" class="pt-2 pb-2 font-weight-thin" v-html="keepPhoneChoiceConfirmationCartText"></v-col>
        </v-row>
      </div>

      <!--
    <v-row v-if="promo">
      <v-col class="py-0  font-weight-thin" cols="8">
        <span>{{ promo.Name }}</span>
      </v-col>
      <v-col class="py-0  text-right" cols="4"
        >{{ promo.Price | coreCurrency }}
      </v-col>
    </v-row>
    --></div>
    <div class="avoid-break">
      <v-row id="payment-row-style" class="grey lighten-3">
        <v-col id="confirmation-cart-style" class="pb-0 grey--text headline">Payment</v-col>
      </v-row>
      <!-- 
      <v-row class="grey lighten-3">
        <v-col class="pb-0 grey--text title">Estimated Monthly Payment</v-col>
        <v-col class="pt-4 text-right grey--text" cols="4">{{ monthlyTotal | coreCurrency }}</v-col>
      </v-row>
      -->
      <v-row id="payment-row-style" class="grey lighten-3 pa-0">
        <v-col cols="12">
          <MonthlyPriceDisplay
            :name="estimatedMonthlyPaymentText"
            :item="cartTotal"
            priceType="Monthly Price"
            myComponentColor="grey"
            additionalTextClasses="title"
            additionalPriceClasses="pt-2"
            :expanded="true"
            class="px-0 ma-0 monthly-total"
          />
        </v-col>
      </v-row>
      <v-row class="grey lighten-3" v-if="showEstimatedFirstPayment">
        <v-col cols="8" class="grey--text title">Estimated First Payment Due</v-col>
        <v-col cols="4" class="pt-4 grey--text text-right">{{ coreCurrency(grandTotal) }}</v-col>
      </v-row>
      <ConfirmationPayment />
    </div>
    <div class="avoid-break">
      <v-row
        v-for="(eDisclaimer, index) in getAllDisclaimers"
        :key="index + 'eDisclaimer'"
        class="grey lighten-3"
        id="payment-row-style"
      >
        <v-col cols="12" class="caption text--secondary">
          <span id="payment-row-style" v-html="uiMacroParser(shopper, eDisclaimer)" />
        </v-col>
      </v-row>
      <v-row class="grey lighten-3">
        <v-col v-if="showPaymentDueText" id="payment-row-style" class="pt-0 pb-3 caption text--secondary">
          <span v-html="uiMacroParser(shopper, paymentHtml)" />
        </v-col>
      </v-row>
    </div>
    <div>
      <!--      --------------------------------------- -->
      <!--                  Payment Options-->
      <!--      --------------------------------------- -->
      <v-row v-if="payType === 'none'" class="pt-1 white payment-options">
        <v-col
          cols="12"
          id="confirmation-cart-style"
          class="grey--text title pb-0 pt-0"
          v-if="paymentOptionsConfirmationHeaderText"
          v-html="uiMacroParser(shopper, paymentOptionsConfirmationHeaderText)"
        ></v-col>
        <v-col cols="12" v-if="paymentOptionsConfirmationMessage">
          <p class="payment-options-message" v-html="uiMacroParser(shopper, paymentOptionsConfirmationMessage)"></p>
        </v-col>
      </v-row>
      <v-row class="white" v-if="contactNumberInCart">
        <v-col class="py-0 caption"> Contact us: </v-col>
      </v-row>
      <v-row class="white pb-2" v-if="contactNumberInCart">
        <v-col class="py-0 font-weight-bold">{{ customerServicePhone }}</v-col>
      </v-row>
      <v-row v-if="customerServiceEmail" class="white pb-2">
        <v-col class="py-0 font-weight-bold">{{ customerServiceEmail }}</v-col>
      </v-row>
      <v-row class="hidden-print-only">
        <v-col class="print-button-col">
          <v-btn
            id="print-button"
            @click="print"
            color="primary"
            :elevation="printButtonElevation"
            :rounded="printButtonRounded"
            :class="defaultButtonColors()"
            >{{ printButtonText }}</v-btn
          >
        </v-col>
        <v-col class="homepage-button-col"
          ><v-btn id="homepage-button" @click="returnToHomepage" :rounded="returnButtonRounded" color="default"
            >Return to Homepage</v-btn
          ></v-col
        >
      </v-row>
    </div>
  </v-container>
</v-card>
